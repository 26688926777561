@import "_Mixin";

$inputWidth: 100%;

.write-form {
  display: flex;
  flex-flow: column;
  position: relative;
  align-items: center;
  width: 90%;
  padding: 10px;
}

.write-type {
  @include font-header();
  align-self: flex-start;
  margin-bottom: 20px;
}

.write-division {
  @include common-border();
  width: 100%;
  height: 40px;
  background-color: white;
}

.write-title {
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #dee2e6;
  width: $inputWidth;
  outline: none;
  font-size: 18px;
  box-sizing: border-box;
}

.write-body {
  margin-top: 10px;
  padding: 8px;
  height: 400px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #dee2e6;
  width: $inputWidth;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: auto;

  :first-child {
    &:empty:before {
      content: attr(placeholder);
      display: block;
      color: #8e8e8e;
    }
  }

  div {
    font-size: 18px;
    width: 100%;
    outline: none;
    height: min-content;

    &:hover {
      cursor: text;
    }
  }
}

.button-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin: {
    top: 10px;
    bottom: 10px;
  }

  :first-child {
    margin-right: auto;
  }

  input[type="file"] {
    @include input-file();
  }

  // label {
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-weight: bold;
  //   outline: none;
  //   border: none;
  //   border-radius: 4px;
  //   cursor: pointer;
  //   padding-left: 1rem;
  //   padding-right: 1rem;
  //   color: white;

  //   font-size: 18px;
  //   font-weight: 600;
  // }
}

.write-img-container {
  max-width: 50%;
  display: table;
  height: max-content;

  margin: {
    left: 2px;
    top: 5px;
    bottom: 5px;
  }

  img {
    display: table-row;
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &:focus {
    outline: 2px solid yellowgreen;
  }
}
