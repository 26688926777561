@import "_Mixin";

@mixin commonTable {
  position: relative;
  width: 90%;
  align-self: center;
  border-collapse: collapse;
  font-size: 1.2rem;

  margin: {
    top: 20px;
    bottom: 20px;
  }

  padding: {
    top: 30px;
    left: 30px;
    right: 30px;
    bottom: 10px;
  }
}

$table-item-height: 30px;

.notice-table {
  @include commonTable;

  .notice-body td {
    text-align: center;
    height: $table-item-height;
  }
}

.community-table {
  @include commonTable;

  .community-body td {
    text-align: center;
    height: $table-item-height;
  }
}

.post-header {
  height: 50px;
  border-bottom: 1px solid $gray30;
  border-top: 1px solid $gray80;
}

.post-item-row {
  height: 50px;
  border-bottom: 1px solid $gray20;
  padding: 5px;

  :link {
    text-decoration: none;
  }

  .post-title {
    color: black;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
