@import "_Mixin";

.footer {
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: auto; // to the end of the Area
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
  // background: #765c4e;
  background: #fafafa;
  // background-color: #E3CCAE;

  .contents {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    font-size: 14px;
    color: #4f7396;
  }

  ul {
    flex: 0.3;
    list-style: none;
    margin-left: 50px;

    li {
      white-space: nowrap;
    }
  }
}

@include mobile {
  .footer {
    height: 80px;

    .contents {
      right: 0;
    }

    .footer-first {
      display: none;
    }

    ul {
      padding: 0;
      flex: 1;
      margin-left: 0px;

      text-align: center;
    }
  }
}
