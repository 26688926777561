.slide-indicator {
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: black;
  opacity: 60%;

  &.selected {
    opacity: 100%;
  }

  & + & {
    margin-left: 5px;
  }
}
