@import "_Mixin";

.post-container {
  margin: 30px;
  padding: 20px;
  padding-bottom: 30px;
  // border-radius: 2px;
  // border: 1px solid $gray80;

  .post-title {
    font-size: 30px;
    font-weight: 500;
  }
  .post-info {
    width: 100%;
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    // image & name
    .post-profile-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      .post-profile {
        @include img-profile();
        margin-right: 10px;
      }
    }

    .post-date {
      right: 0;
    }
  }

  .post-body {
    min-height: 300px;
    font-size: 1.2rem;
  }

  .button-container {
  }

  .comment-container {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;

    .comment-reply {
      position: relative;
      display: none;
      margin-left: 25px;

      &.visible {
        display: block;
      }
    }
  }

  .comment-body-container {
    @include flex-column();
    padding: 15px;

    &.reply {
      margin-left: 25px;
    }

    .comment-profile {
      @include img-profile();
    }

    .comment-info-profile {
      @include flex-row-align-center();
    }

    .comment-nickname {
      font-size: 18px;
      font-weight: 600;
      margin-left: 10px;
      color: indigo;
    }

    .comment-content {
      font-size: 18px;
      margin: 0px;
      padding-top: 10px;
      padding-bottom: 10px;

      &.deleted {
        color: gray;
      }
    }

    .comment-date {
      font-size: 15px;
      font-weight: 400;
    }
  }

  .comment-reply-button {
    margin-top: 10px;
    color: $gray80;

    &:hover {
      cursor: pointer;
    }
  }
}

.comment-write-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.reply {
    margin-top: 5px;
    margin-left: 50px;
    margin-bottom: 20px;
  }
}

.comment-write {
  position: relative;
  width: 100%;
  height: 80px;
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  margin-right: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  resize: none;
}

.comment-button-container {
  margin: 0;
  display: flex;
  height: 80px;
  flex-direction: column;
  width: min-content;
  justify-content: center;

  &.reply {
    justify-content: space-between;
  }

  .styled-button {
    width: fit-content;
    margin-left: 0;
  }
}

.divider-horizontal {
  @include divider-horizontal();
  margin: {
    top: 15px;
    bottom: 15px;
  }

  &.comment {
    margin: {
      top: 5px;
      bottom: 10px;
    }
  }
}

.divider-vertical {
  @include divider-vertical();
  margin: {
    left: 10px;
    right: 10px;
  }
}
