@import "_Mixin.scss";

.church-container {
  width: 100%;

  .section-divider {
    @include section-divider();
  }

  span {
    // @include font-header();
  }

  .church-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &.vertical {
      flex-direction: column;
    }

    p {
      margin: 0px;
      font-size: 20px;
      font-family: "noto medium";
      line-height: 200%;
      letter-spacing: 0.1cap;

      &.intro-people-title {
        text-align: center;
        font-family: "noto bold";
      }
    }

    .church-intro-image {
      width: 30%;
      border-radius: 10px;
    }

    .intro-people-senior {
      width: 700px;
      margin-top: 50px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;

      img {
        width: 180px;
        background-color: #404047;
        border-radius: 50%;
      }

      .senior-text-container {
        margin-left: 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .senior-name {
          font-size: 22px;
        }
      }
    }

    .intro-people-assistant {
      width: 800px;
      margin-top: 30px;
      margin-bottom: 50px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: center;
      .assistant-body {
        img {
          width: 180px;
          border-radius: 50%;
          background-color: #e0e0e0;
        }

        .assistant-name {
          font-size: 18px;
          font-family: "noto medium";
        }
        .assistant-part {
          font-size: 16px;
        }
      }
      div {
        margin-top: 10px;
      }
    }
  }

  .church-school-grid {
    width: 1000px;
    margin-top: 50px;
    margin-bottom: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
    column-gap: 50px;

    .school-grid-item {
      img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 8px;
      }

      p {
        width: 100%;
        font-size: 20px;
        text-align: center;
        font-family: "noto medium";
      }
    }
  }
}

@include desktop {
  .church-intro-mobile {
    display: none;
  }
  .church-intro-image {
    margin-top: 20px;
    margin-left: 100px;
  }
}

@include mobile {
  .church-container {
    .church-section {
      flex-direction: column;
      align-items: center;

      .church-intro-desktop {
        display: none;
      }

      .intro-people-title {
        font-family: "noto bold";
      }

      p {
        width: 70%;
        line-height: 160%;
        font-family: "noto regular";
      }

      .church-intro-image {
        margin-top: 50px;
        width: 80%;
      }

      .intro-people-assistant {
        width: 90%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
        margin-bottom: 0px;

        .assistant-body {
          img {
            width: 120px;
            border-radius: 50%;
            background-color: #e0e0e0;
          }
          div {
            margin-top: 5px;
          }
        }
      }
    }

    .church-school-grid {
      width: 90%;
      grid-template-columns: 1fr;
      gap: 30px;

      p {
        margin-top: 10px;
      }

      .school-grid-item {
        img {
          height: 250px;
        }
      }
    }
  }
}
