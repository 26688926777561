$blue: #228be6;
$gray: #495057;
$pink: #f06595;

@mixin button-color($color) {
  background: $color;

  &:hover {
    background: lighten($color: $color, $amount: 10%);
  }

  &:active {
    background: darken($color: $color, $amount: 10%);
  }

  &.outline {
    color: $color;
    background: none;
    border: 1px solid $color;

    &:hover {
      background: $color;
      color: white;
    }
  }
}

.styled-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;

  &.complete {
    font-size: 20px;
    font-weight: 600;
    @include button-color($blue);
  }

  &.cancel {
    font-size: 20px;
    font-weight: 600;
    @include button-color($pink);
  }

  &.normal {
    font-size: 18px;
    font-weight: 600;
    @include button-color($gray);
  }

  &+& {
    margin-left: 1rem;
  }

  &.fullWidth {
    width: 100%;
    justify-content: center;

    &+& {
      margin-left: 0;
      margin-top: 1rem;
    }
  }
}