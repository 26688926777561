@import "_Mixin";

.home-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background: linear-gradient(180deg, #ddd7cbfa, #fafafa 30%);

  .main-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  .main-menu-container {
    display: flex;
    width: 900px;
    // background: linear-gradient(180deg, white, #E3CCAE);
    flex-direction: column;
    align-items: center;
    height: auto;
    // padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    box-sizing: border-box;

    .main-menu-body {
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}

@include mobile {
  .home-container {
    .main-menu-container {
      width: 100%;

      .main-menu-body {
        height: auto;
        flex-wrap: wrap;
        row-gap: 20px;
        justify-content: center;
        column-gap: 20px;
      }
    }
  }
}
