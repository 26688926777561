@import "_Mixin";

.menu-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(((100%) / 5) - 20px);
  padding-top: 30px;
  padding-bottom: 30px;
  // aspect-ratio: 1/1;
  border: solid transparent 1px;
  border-radius: 20px;

  &:hover {
    border: solid #ededed 1px;
    box-shadow: 1px 2px 4px #00000040;

    .menu-button-title {
      color: black;
    }
  }

  a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .menu-button-title {
    text-align: center;
    color: #3e3e3e;
    font-family: "Noto Medium";
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  .menu-button-img {
    width: 40px;
    height: 40px;
  }
}

@include mobile {
  .menu-button-container {
    width: calc((100% - 50px) / 2);
    border: solid #ededed 1px;
    box-shadow: 0px 1px 4px #00000040;

    .menu-button-img {
      width: 32px;
      height: 32px;
    }
  }
}
