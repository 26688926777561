@import "_Mixin";

.section {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: #fafafa;
  // background-color: burlywood;

  // &.right > .section-title {
  //   margin-left: auto;
  // }
}

.section-body {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  height: 100%;
  padding {
    top: 20px;
    left: 50px;
    bottom: 20px;
    right: 50px;
  }
  // background-color: aliceblue;

  .section-body-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
  }
}

.section-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  order: 0; // left or right
  padding-top: 40px;
  padding-bottom: 40px;
  // margin-bottom: 20px;
  font-family: "noto bold";
  font-size: 32px;
  color: black;
  // background: linear-gradient(180deg, #303f6dab, transparent);
  background: linear-gradient(180deg, #ddd7cbfa, transparent);
  // background: linear-gradient(#e7acac, #ffffff);
}

.section-sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.section-sub-item {
  display: flex;
  justify-content: center;
  margin: 0;
  width: 120px;
  height: 40px;
  margin-top: 10px;
  font-size: 22px;
  white-space: nowrap;

  @include link(120px, 40px, flex-start);

  p {
    color: #3a3a3a;
    white-space: nowrap;
    justify-self: flex-start;
  }

  .sub-menu {
    span {
      font-family: "noto regular";
      text-align: center;
      width: 100%;
      color: #3a3a3a;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &.selected {
      display: flex;
      align-items: center;
      text-decoration: underline;
      text-underline-offset: 8px;

      span {
        font-family: "noto bold";
      }
    }
  }
}

@include desktop {
  .section {
    .section-sub {
      // margin-top: 20px;
      // margin-bottom: 20px;
    }
    // .section-sub-item {
    //   .sub-menu {
    //   }
    // }
  }
}

@include mobile {
  .section {
    flex-direction: column;
  }

  .section-body {
    width: 100%;
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 20px;
  }

  .section-title {
    width: 100%;
    height: auto;
    order: 0;
    padding: {
      top: 20px;
      bottom: 20px;
    }
    box-sizing: border-box;
    // display: block;
    // background-color: #575757;

    .title-hidden {
      display: none;
    }

    span {
      display: flex;
      align-items: center;
      position: relative;
      // padding-left: 20px;
      left: 0px;
      // margin-top: 20px;
      // height: 50px;
      // color: white;
      font-size: 24px;
      font-family: "noto medium";
    }

    .section-sub {
      display: flex;
      // background-color: white;
      justify-content: start;
      padding-left: 20px;
      margin-top: 5px;
      // background-color: aliceblue;

      .section-sub-item {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: start;
        width: 80px;

        a {
          width: auto;
        }

        span {
          margin: 0;
          padding: 0;
          height: 40px;
          color: #3a3a3a;
          font-family: "noto regular";
          font-size: 16px;
        }

        .sub-menu.selected {
          span {
            color: black;
            // margin-top: 10px;
            // margin-bottom: 10px;
            // font-size: 18px;
            font-family: "noto medium";
          }
        }
      }
    }
  }
}
