@import "_Mixin";

.banner-container {
  display: flex;
  width: 900px;
  height: 450px;
  padding: 16px;
  // background: linear-gradient(180deg,  #E3CCAE,  white);
  position: relative; //for exact child absolute pos
  flex-flow: column nowrap;
  align-items: center;
}

.scroll-view {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  position: relative;
  flex-direction: row;
  list-style: none;
  overflow-x: hidden;
  flex-wrap: nowrap;
  white-space: nowrap;

  li {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    div {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    // .banner-first {
    // width: 100%;
    // background-color: #025c99;
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 12px;
    }
  }
}

@include mobile {
  .banner-container {
    display: flex;
    width: 100%;
    height: 280px;
    padding: 0px;
  }

  .scroll-view {
    margin-top: 0px;
    li {
      img {
        border-radius: 0px;
      }
    }
  }
}
