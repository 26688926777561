.page-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-ul {
  float: left;
  list-style: none;
  text-align: center;
  border-radius: 3px;
  color: white;
  padding: 1px;
  border-top: 3px solid #186ead;
  border-bottom: 3px solid #186ead;
  background-color: rgba(0, 0, 0, 0.4);
}

.page-li {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  padding: 5px;
  border-radius: 5px;
  width: 25px;

  &.selected {
    color: white !important;
    background-color: #098209 !important;
  }

  &:hover:not(.selected) {
    cursor: pointer;
    color: white;
    background-color: #263a6c;
  }

  &:focus {
    color: white;
    background-color: #263a6c;
  }

  & + & {
    margin-left: 10px;
  }
}

.page-span {
  // &:hover::after,
  // &:focus::after {
  //   border-radius: 100%;
  //   color: white;
  //   background-color: #ff0000;
  // }
}
