@import "_Mixin";

.bulletin-grid-container {
  display: grid;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  //   grid-template-columns: repeat(3, 1fr);
  //   grid-template-columns: repeat(auto-fill, minmax(50%, auto));
  grid-template-columns: repeat(auto-fill, 800px);
  grid-template-rows: repeat(auto-fill);
  row-gap: 20px;
  column-gap: 20px;
  justify-content: center;
  align-content: center;

  .bulletin-grid-item {
    background-color: white;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: black;

    &:hover {
      cursor: pointer;
    }

    .bulletin-thumbnail {
      width: 100%;
      border: 0px;
    }

    .div-info-container {
      padding: 5px;

      .div-title {
        font-size: 24px;
        text-align: center;
      }
    }
  }
}

@include mobile {
  .bulletin-grid-container {
    grid-template-columns: repeat(1, 300px);
    grid-template-rows: repeat(2);
    row-gap: 20px;
    column-gap: 20px;

    .bulletin-grid-item {
      .div-info-container {
        .div-title {
          // font-size: 1.5rem;
          // font-weight: 500;
        }
      }
    }
  }
}
