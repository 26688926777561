@import "_Mixin";
.grid-container {
  display: grid;
  width: 100%;
  padding: 10px;
  // padding-bottom: 50px;
  box-sizing: border-box;
  //   grid-template-columns: repeat(3, 1fr);
  //   grid-template-columns: repeat(auto-fill, minmax(50%, auto));
  grid-template-columns: repeat(3, minmax(0, 25%));
  // grid-template-rows: repeat(auto-fill);
  row-gap: 50px;
  column-gap: 50px;
  justify-content: center;
  align-content: center;

  .grid-item {
    display: flex;
    flex-direction: column;

    &:hover {
      cursor: pointer;
    }

    .img-thumbnail {
      width: 100%;
      border: 0px;
      border-radius: 20px;
    }

    .div-info-container {
      padding: 10px;

      .div-title {
        font-size: 20px;
        font-family: "noto medium";
        text-align: center;
      }
      .div-subtitle {
        font-size: 0.8rem;
        margin-bottom: 5px;
      }
    }
  }
}

@include mobile {
  .grid-container {
    grid-template-columns: repeat(1, 80%);
    grid-template-rows: repeat(auto-fill);
    row-gap: 30px;

    .grid-item {
      .div-info-container {
        .div-title {
          // font-weight: 600;
        }
      }
    }
  }
}
