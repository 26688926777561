@import "_Mixin";

.login-container {
    height: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    width: 100%;
    height: 100%;

    background-color: gray;

    .login-title {
        font-size: 40px;
        font-weight: 400;
    }

    @include link(100px, 30px, center);

    p {
        color: blue
    }
}