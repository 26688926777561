@import "_Mixin";

.signup-container {
  @include flex-column();
  align-items: center;
  height: 100%;
  background-color: gray;
  padding: 30px;
}

.signup-form {
  width: 300px;

  .signup-title {
    font-size: 30px;
    font-weight: 400;
    text-align: center;
  }

  .signup-input-title {
    font-size: 20px;
    margin-top: 10px;

    &.margin {
      margin-top: 40px;
    }
  }
}
