@import "_Mixin.scss";

.modal-alert-bg {
  @include modal-background();
}

.modal-alert-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 500px;
  border: 1px solid #ccc;
  padding: 16px;
  box-shadow: 1px 1px 1px black;
  top: 30%;
  z-index: 1000;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #e8e8e8;
}

.modal-image-container {
  top: 15%;
  width: 600px;
  height: 450px;
  padding: 0;
  background-color: transparent;
  border: transparent;
  box-shadow: none;

  .modal-image {
    width: 100%;
    height: 100%;
    background-color: white;
  }
}

.modal-video-container {
  top: 15%;
  width: 800px;
  height: 450px;
  padding: 0;
  background-color: transparent;
  border: transparent;
  box-shadow: none;

  .modal-video {
    width: 100%;
    height: 100%;
    background-color: white;
  }
}

@include mobile {
  .modal-image-container {
    top: calc(50% - 150px);
    width: 400px;
    height: 300px;
    -ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
  }

  .modal-video-container {
    width: 300px;
    height: 250px;

  }
}
