@import "_Mixin";

$color-blue: #00aeef;
$color-blue-dark: #0d2035;
$submenu-width: 120px;

@include desktop {
  .nav {
    margin-top: 30px; //todo:account ver
  }

  .nav-menu-button {
    display: none;
  }

  .nav-menu-item {
    // height: 40px;

    p {
      // padding-bottom: 10px;
    }

    &:hover {
      cursor: pointer;
      // background-color: rgba(255, 235, 205, 0.8);

      .nav-sub {
        // display: block;
        opacity: 1;
        visibility: visible;
      }

      .nav-menu-underbar {
        // display: block;
        opacity: 1;
      }

      .nav-menu-title {
        color: #4e4e4e;
      }
    }
  }
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  background-color: white;
  // background-color: #91a0d1;
  // background: linear-gradient(white, #fafafa);

  .header-container {
    display: flex;
    width: 90%;
    max-width: 1200px;
    height: calc(100% - 1px);
    margin: 0;
    align-items: end;
    justify-content: space-between;
  }

  .header-bottom-divider {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    // border: solid #ededed 0.5px;
    background-color: #c6c1b3;
    // box-shadow: 0px 1px 2px #0000005a;
    // background: linear-gradient(180deg, #afafaf, white);
  }
}

.header-login {
  display: flex;
  flex-flow: row wrap;
  margin-right: 20px;
  visibility: hidden;

  @include link(100px, 20px, center);

  :link {
    margin-left: 10px;
  }

  span {
    color: black;
    margin: 0;
    position: relative;
    display: block;
    font-size: 0.8rem;
  }

  p + p {
    margin-left: 10px;
  }

  .header-img-link {
    width: 20px;
    margin-right: 5px;
  }
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-right: 50px;
}

.nav-logo {
  padding-left: 50px;
  display: flex;
  height: 100%;
  flex-direction: row;
  // font-size: xx-large;
  // font-weight: 700;
  align-items: center;

  img {
    height: 50px;
  }
}

.nav-menu {
  display: flex;
  width: 100%;
  list-style: none;
  align-items: center;
  padding-left: 0px;
  z-index: 99;
  margin: 0px; // for hovering dropdown
}

.nav-menu-item {
  // background-color: blanchedalmond;
  display: block;
  position: relative;
  width: 120px;
  height: 60px;

  a {
    display: flex;
    flex-direction: column;
    height: 100% !important;
  }

  .nav-menu-dropdown {
    height: 100%;
    display: flex;
    align-items: center;
    p {
      text-align: center;
      flex: auto;
    }
  }

  @include link($submenu-width, 40px, center);

  p {
    margin: 0;
    color: black;
    font-family: "Noto Regular";
    font-size: 18px;
  }

  & + & {
    margin-left: 10px;
  }
}

.nav-menu-underbar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  opacity: 0;
  background-color: #c6c1b3;

  &.selected {
    opacity: 1;
  }
}

.nav-sub {
  // display: none;
  text-transform: none;
  list-style: none;
  position: absolute;
  padding: 0;
  width: $submenu-width;
  // background-color: $color-blue;
  background-color: #765c4e;
  // margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  z-index: 99;
  transition: opacity 0.3s;
}

.nav-sub-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $submenu-width;
  height: 50px;

  p {
    color: white;
    font-size: 16px;
    font-family: "noto regular";
  }

  &:hover {
    background: rgba(#000, 0.1);
  }
}

@include mobile {
  .header {
    position: sticky;
    z-index: 100;
    // position : sticky => height:50px -> 47px in web
    min-height: 50px;
    max-height: 50px;

    .header-container {
      height: 100%;
      width: 100%;
      align-items: center;
    }
  }

  .header-login {
    margin-right: 50px;
    // margin-top: 0px;
    height: 100%;
    display: none;

    span {
      display: none;
    }

    .header-img-link {
      width: 30px;
      margin-right: 0;
    }

    a {
      width: 30px !important;
    }
  }

  .nav {
    display: none;

    &.active {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      background-color: white;
      top: 50px;
      z-index: 100;
    }
  }

  .nav-logo {
    padding-left: 20px;
    height: 40px;
    // font-size: x-large;
    // font-weight: 600;

    img {
      height: 40px;
    }
  }

  .nav-menu {
    flex-direction: column;
    padding: 0;
    margin: 0;
  }

  .nav-menu-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: #bbc9cf;
    // border: solid 0.1px #e6e6e6;

    & + & {
      margin-left: 0px;
    }

    a {
      // width: 100% !important;
      height: 50px !important;
      min-width: -webkit-fill-available;
    }

    &:hover {
      cursor: pointer;
    }

    .nav-menu-dropdown {
      text-align: center;
      height: 50px;
      // &.active {
      // }
    }

    .nav-sub.active {
      position: relative;
      width: 100%;
      opacity: 1;
      visibility: visible;

      .nav-sub-item {
        width: 100%;
        height: auto;
      }
    }
  }

  .nav-menu-button {
    display: inline;
    margin-right: 10px;

    :hover {
      cursor: pointer;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
}
