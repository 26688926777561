$breakpoint-desktop: 800px;

$gray20: rgba(128, 128, 128, 0.2);
$gray30: rgba(128, 128, 128, 0.3);
$gray80: rgba(128, 128, 128, 0.8);

@mixin mobile {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin link($width, $height, $justify) {
  :link {
    display: flex;
    justify-content: $justify;
    align-items: center;
    width: $width;
    height: $height;
    text-decoration: none;
  }
}

@mixin font-header() {
  font-size: 24px;
  font-family: "noto medium";
  margin-bottom: 10px;
  @include mobile {
    font-size: 22px;
  }
}

@mixin font-medium() {
  font-size: 20px;
  @include mobile {
    font-size: 18px;
  }
}

@mixin common-border() {
  border-radius: 4px;
  border: 1px solid #dee2e6;
  outline: none;
  box-sizing: border-box;
}

@mixin input-file {
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-row-align-center {
  @include flex-row();
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin img-profile {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid $gray20;
}

@mixin divider-horizontal {
  width: 100%;
  height: 1px;
  background-color: $gray20;
}

@mixin section-divider {
  position: relative;
  width: 80%;
  margin-top: 50px;
  left: 10%;
  margin-bottom: 50px;
  height: 1px;
  background-color: #afafaf;
}

@mixin divider-vertical {
  width: 2px;
  height: 100%;
  background-color: $gray20;
}

@mixin modal-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: #0000005d;
}

@mixin font-face($name, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    src: url("/fonts/#{$file}.otf") format("opentype");
    font-weight: $weight;
    font-style: $style;
  }
}

@include font-face("Noto Regular", "NotoSansKR-Regular");
@include font-face("Noto Bold", "NotoSansKR-Bold", bold);
@include font-face("Noto Medium", "NotoSansKR-Medium");
@include font-face("Noto Light", "NotoSansKR-Light");
