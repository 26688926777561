@import "_Mixin.scss";

.worship-container {
  span {
    @include font-header();
    margin-left: 10px;
  }
}

.worship-table {
  border: 1px solid #dddddd;
  border-collapse: collapse;
  border-radius: 10px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #dddddd;
  width: 800px;
  margin-top: 20px;
  margin-bottom: 50px;

  thead {
    :first-child {
      :first-child {
        border-top-left-radius: 10px;
      }
      :last-child {
        border-top-right-radius: 10px;
      }
    }
  }

  tbody {
    :last-child {
      :first-child {
        border-bottom-left-radius: 10px;
      }
      :last-child {
        border-bottom-right-radius: 10px;
      }
    }
  }

  th {
    width: 33%;
    background-color: #333;
    border: #333;
    height: 50px;
    border: 1px solid #dddddd;
    color: #fff;
    font-size: 18px;
    padding: 0;

    :first-child {
      border-top-left-radius: 20px;
    }
  }

  td {
    height: 50px;
    border: 1px solid #dddddd;
    text-align: center;
    font-size: 16px;
  }
}

@include mobile {
  .worship-container {
  }

  .worship-table {
    width: 310px;
    margin-bottom: 30px;

    th:nth-child(1) {
      width: 30%;
    }

    th:nth-child(2) {
      width: 30%;
    }
    th:nth-child(3) {
      width: 40%;
    }
  }
}
