@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 40px;
  height: 40px;
  border: 10px solid #d7d7d7; /* background */
  border-top: 10px solid #383636; /* tint */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}
