@import "_Mixin";

.bulletin-pager-container {
  .bulletin-desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
  }

  .bulletin-mobile {
    display: none;
  }

  .bulletin-pager-header {
    display: flex;
    width: 500px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .bulletin-title {
      font-size: 26px;
      font-family: "noto medium";
      text-align: center;
    }
    .bulletin-pager-arrow {
      width: 25px;
      height: 25px;

      &.left {
        rotate: (180deg);
      }

      &:not(.disabled) {
        &:hover {
          cursor: pointer;
        }
      }

      &.disabled {
        visibility: hidden;
      }
    }
  }

  .bulletin-pager-body {
    width: 80%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .bulletin-thumbnail {
      width: 1000px;
      border: 0px;
    }
  }
}

@include mobile {
  .bulletin-pager-container {
    align-content: center;

    .bulletin-desktop {
      display: none;
    }

    .bulletin-mobile {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 500px;
      padding : {
        top: 50px;
        bottom: 50px;
      }
      column-gap: 20px;
      box-sizing: border-box;
    }
  }

  .bulletin-mobile-item {
    color: black;
    text-underline-offset: 10px;
    letter-spacing: 0.1cap;

    &:hover {
      cursor: pointer;
    }

    & + & {
      margin-top: 40px;
    }

    .bulletin-mobile-title {
      text-align: center;
      font-size: 24px;
      font-family: "noto bold";
    }
  }
}
