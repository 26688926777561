@import "_Mixin.scss";

.location-container {
  span {
    @include font-header();
  }

  .location-info {
    margin-top: 10px;
    @include font-medium();
  }

  .location-transport {
    margin-top: 50px;
    margin-bottom: 50px;
    @include font-medium();

    > div {
      margin-top: 5px;
    }

    .location-subway {
      display: flex;
      white-space: nowrap;
      flex-direction: row;

      #subway-multiline {
        white-space: initial;
      }
    }
  }

  #map {
    margin-top: 10px;
    width: 600px;
    height: 400px;
  }
}

@include mobile {
  .location-container {
    padding-left: 10px;
    padding-right: 10px;
    width: 90%;

    .location-map-container {
      width: 100%;
      display: flex;
    }
    #map {
      // width: calc(100% - 20px);
      height: 220px;
    }
  }
}
