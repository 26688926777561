@import "_Mixin";

.community-header {
  margin: 0;
  margin-bottom: 30px;
  height: auto;
  align-self: center;
}

.video-more-container {
  display: flex;
  justify-content: center;

  .video-more {
    width: 200px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    text-decoration: none;
    color: black;
    font-family: "noto bold";
    font-size: 20px;
    margin-top: 20px;
    border: solid #00000000 1px;
    margin-bottom: 30px;

    &:hover {
      border: solid #ededed 1px;
      box-shadow: 1px 2px 4px #00000040;

      .menu-button-title {
        color: black;
      }
    }
    img {
      width: 40px;
      margin-right: 10px;
    }
  }
}

.notice-header {
  margin: 0;
  margin-bottom: 30px;
  height: auto;
  align-self: center;
}

.write-button {
  background: #38d9a9;

  &:hover {
    background: #63e6be;
  }

  &:active {
    background: #20c997;
  }
  display: none !important;

  width: 40px;
  height: 40px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 80%;
  bottom: 0px;

  font-size: 40px;
  color: white;
  border-radius: 40px;

  border: none;
  outline: none;

  transition: 0.125s all ease-in;
  //   ${(props) =>
  //     props.open &&
  //     css`
  //       background: #ff6b6b;
  //       &:hover {
  //         background: #ff8787;
  //       }
  //       &:active {
  //         background: #fa5252;
  //       }
  //       transform: translate(-50%, 50%) rotate(45deg);
  //     `}
}

@include mobile() {
  .video-more-container {
    .video-more {
      border: solid #ededed 1px;
      box-shadow: 1px 2px 4px #00000040;
    }
  }
} ;
